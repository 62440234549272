/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var headerHeight = $('#masthead').outerHeight(),
		menuButton = $('.menu-button');
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();

		// Adjust margin for page without header images
		
		if ( $('body').hasClass('no-header-images') ) {
			$('#content').css( 'padding-top', headerHeight );
		}

		$('#pre-content').css( 'padding-top', headerHeight );

		// Intro Animations (not home)

		$('body:not(.home) #page').addClass( 'init' );

		$('.accordion .heading').on('click', function(e) {
			e.preventDefault();
			var $this = $(this);

			if ( !$this.hasClass( 'active' ) ) {
				$('.accordion .content').slideUp(400);
				$('.accordion .heading').removeClass( 'active' );
			}

			$this.toggleClass( 'active' );
			$this.next().slideToggle();
		});

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Menu
		var menu = gsap.timeline({
			paused: true,
			onStart: function() {
				$('body').addClass( 'open-menu' );
			},
			onReverseComplete: function() {
				$('body').removeClass( 'open-menu' );
				
				submenus.forEach((submenu, index) => {
					tween[index].reverse();
				});
			}
		});

		menu.to(
			'#menu-offcanvas',
			{
				duration: 0.6,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'.menu-item',
			{
				duration: 0.6,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.02
			},
			'-=0.1'
		).to(
			'.menu-bottom',
			{
				opacity: 1,
				ease: 'Power2.inOut'
			}
		);

		// Sub items menu
		var submenus = gsap.utils.toArray('.sub-menu'),
			tween = [];

		submenus.forEach((submenu, index) => {
			tween[index] = gsap.from(
				submenu,
				{
					duration: 0.1,
					height: 0,
					paused: true,
					reversed: true,
					ease: 'Power2.inOut'
				}
			);
		});


			if ( $(window).width() > 1023 ) {
				// Change color to menu button
				ScrollTrigger.create({
					start: 'top top',
					end: 99999,
					toggleClass: {
						targets: '.menu-bar',
						className: 'menu-button-scrolled'
					}
				});
			}
			if ( $(window).width() > 1023 ) {
				// Header hide/show on scroll (Desktop)
				var header = gsap.timeline({
					paused: true
				});
	
				header.from(
					'#masthead .row .col-6.mobi',
					{
						yPercent: -120,
						duration: 0.2
					}
				).progress(1);
	
				ScrollTrigger.create({
					start: 'top top',
					end: 99999,
					toggleClass: {
						targets: '#masthead .row .col-6.mobi ',
						className: 'upward'
					},
					onUpdate: (self) => {
						self.direction === -1 ? header.play() : header.reverse()
					}
				});
			// Intro animation (Home)
			if ( $('body').hasClass('home') ) {
				var intro = gsap.timeline({
					paused: true,
					onComplete: function() {

						$('#masthead').css( 'opacity', 1 );
					}
				});
			
				intro.to(
					'.hd-media',
					{
						delay: 1,
						duration: 1,
						opacity: 1
					}
				).to(
					'.hd-media',
					{
						delay: 0.5,
						duration: 1,
						scale: 1
					}
				)
					
				.to(
					'.menu-button',
					{
						delay: 0.5,
						duration: 0.5,
						opacity: 1
					}
				).to(
					'#main-cta',
					{
						duration: 0.5,
						opacity: 1
					},
					'<'
				);

				intro.play();
			}
		}

		if ( $(window).width() > 767 ) {
			// Additional image
			var entry_image = $('.entry-image');

			if ( entry_image.length > 0 ) {
				gsap.from(
					entry_image,
					{
						scrollTrigger: {
							trigger: entry_image,
							start: 'top bottom',
							toggleClass: 'on',
							toggleActions: 'restart pause resume reverse'
						}
					}
				);
			}

			// Titles (sliding up)
			var titles = gsap.utils.toArray('.slide');

			if ( titles.length > 0 ) {
				titles.forEach((title) => {
					var delay = title.getAttribute('data-delay'),
						scroll = title.getAttribute('data-scroll'),
						start = 'top center';

					if ( scroll == 'no' ) start = 'top bottom';

					title.parentNode.style.overflow = 'hidden';

					gsap.from(
						title,
						{
							delay: delay,
							duration: 1.5,
							yPercent: 120,
							scrollTrigger: {
								trigger: title,
								start: start,
								toggleActions: 'restart pause resume reverse'
							}
						}
					);
				});
			}

			// Texts (fading up)
			var texts = gsap.utils.toArray('.fade');

			if ( texts.length > 0 ) {
				texts.forEach((text) => {
					var delay = text.getAttribute('data-delay'),
						scroll = text.getAttribute('data-scroll'),
						start = 'top center';

					if ( scroll == 'no' ) start = 'top bottom';

					gsap.from(
						text,
						{
							delay: delay,
							duration: 1,
							autoAlpha: 0,
							y: 50,
							scrollTrigger: {
								trigger: text,
								start: start,
								toggleActions: 'restart pause resume reverse'
							}
						}
					);
				});
			}
			//image reveal

			var containers = gsap.utils.toArray('.reveal');

			if ( containers.length > 0 ) {
				containers.forEach((container) => {
					var image = container.querySelector('img'),
						tl = gsap.timeline({
							scrollTrigger: {
								trigger: container,
								start: 'top center'
							}
						});
					
					tl.set(
						container,
						{
							autoAlpha: 1
						}
					);

					if ( container.classList.contains('right-side') ) {
						tl.from(
							container,
							2,
							{
								xPercent: 100,
								ease: 'Power2.easeOut'
							}
						);

						tl.from(
							image,
							2,
							{
								xPercent: -100,
								scale: 1.3,
								delay: -2,
								ease: 'Power2.easeOut'
							}
						);
					} else {
						tl.from(
							container,
							2,
							{
								xPercent: -100,
								ease: 'Power2.easeOut'
							}
						);

						tl.from(
							image,
							2,
							{
								xPercent: 100,
								scale: 1.3,
								delay: -2,
								ease: 'Power2.easeOut'
							}
						);
					}
				});
			}


			// Vertical line
			var line = $('#line'),
			container = line.hasClass('rooms') ? $('#rooms-list') : $('#offers-list');

			if ( line.length > 0 ) {
				gsap.from(
					line,
					{
						height: 0,
						ease: 'none',
						scrollTrigger: {
							trigger: container,
							end: '80%',
							scrub: 0.5,
							once: true
						}
					}
				);
			}
		}

		/* GSAP Animations - End */

		// Activate/Deactivate sub-menus

		$('#primary-menu').find('a[href="#"]').each(function(i) {
			$(this).on('click', function(e) {
				e.preventDefault();

				tween[i].reversed() ? tween[i].play() : tween[i].reverse();
			});
		});

		// Hide/show menu off canvas
		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass('collapsed');
		
			if ( ! $(this).hasClass('collapsed') ) {
				$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
				$('#logo').css('display', 'none');  // Nascondi il logo
				menu.play();
			} else {
				$('#logo').css('display', 'block');  // Mostra il logo
				menu.reverse();
			}
		});
		
});


	// Code to run when the browser window has been resized

	var timer_id;

	$( window ).on( 'resize', function() {

		clearTimeout(timer_id);
		timer_id = setTimeout(function() {

			if ( $(window).width() >= 1024 ) {
				// Adjust margin for page without header images
		
				if ( $('body').hasClass('no-header-images') ) {
					$('#content').css( 'padding-top', headerHeight );
				}

				$('#pre-content').css( 'padding-top', headerHeight );

				// Equal Heights
				
				$('.equalHeight').equalHeights();
			}
			
		}, 300);

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Rooms slideshow

const roomsSlider = new Swiper('#rooms .rooms-slideshow', {
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: false,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#rooms .swiper-button-next',
		prevEl: '#rooms .swiper-button-prev',
	},
});

// Offers slideshow

const offersSlider = new Swiper('#offers .offers-slideshow', {
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 1000,
	allowTouchMove: false,
	navigation: {
		nextEl: '#offers .swiper-button-next',
		prevEl: '#offers .swiper-button-prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 90,
			speed: 1500,
		},
	},
});

// Reasons slideshow

const reasonsSlider = new Swiper('#reasons .reasons-slideshow', {
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: false,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#reasons .swiper-button-next',
		prevEl: '#reasons .swiper-button-prev',
	},
});

// Reviews slideshow

const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: false,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	navigation: {
		nextEl: '#reviews .swiper-button-next',
		prevEl: '#reviews .swiper-button-prev',
	},
});

// Social slideshow

const socialSlider = new Swiper('#social .social-slideshow', {
	loop: true,
	centeredSlides: true,
	slidesPerView: 1.75,
	spaceBetween: 15,
	speed: 1000,
	allowTouchMove: false,
	autoplay: {
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2.75,
			spaceBetween: 30,
			speed: 1000
		}
	},
});

/* Swiper - End */

// Lightbox effects

const lightbox = GLightbox({
	loop: true
});
